import {
  getAvailableRepairers,
  getRepairClaimSummary,
  getRepairs,
  getRepairById,
  recordRepairerContactReveal,
  recordOwnerContactReveal,
  getRepairerDetails,
  getOwnerDetails,
  addRepairActivity,
  searchRepairs,
  searchRepairsDual,
  getSiteCodes,
  updateRepair,
  getEngineers,
  updateEngineer,
  getDtlList,
  getRepairGroups,
  getNpsSummaryRepairGroups,
} from 'api/resources/repairersService';
import { getHelpContext, getUser } from 'api/resources/userService';
import { logger } from 'core/logger';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMutation, useQuery } from 'react-query';
import {
  AccountProfile,
  AccountProfilePermit,
  AccountProfilePermits,
  AddActivity,
  BaseUserAccount as ApiBaseUserAccount,
  ClaimSummary,
  Company,
  CycleTimeRequestModel,
  DataAccessProfile,
  Engineer,
  OwnerReason,
  Repair,
  RepairerGroupModel,
  RepairerMetaData,
  RepairerReason,
  RepairManualUpdate,
  SortOrder,
  User,
  UserAccount,
  WorkProvider,
} from 'api/resources/models/AutoGenerated';
import { useAppSelector } from 'core/store';
import {
  AccountProfilesConfig,
  addAllSiteCodeForFormalCode,
  addSiteCodeFormalCodeAssign,
  addWorkProviderformalcodes,
  createAccountProfile,
  createCompany,
  createDataAccessProfile,
  createUserAccount,
  disableUserAccount,
  enableUserAccount,
  getAccountProfile,
  getAccountProfilePermits,
  getAccountProfiles,
  getAccountProfilesByCompanyId,
  getALLSiteCodeFormalCode,
  getAssignableAccountProfilesByCompanyId,
  getCompanies,
  getCompaniesById,
  getDataAccessProfile,
  getDataAccessProfiles,
  getUserAccount,
  getUserAccountProfiles,
  getUserAccountsByCompanyId,
  getUserAccountsByProfileId,
  getUsers,
  getWorkProviderFormalCodes,
  impersonateUser,
  notifyUser,
  removeSiteCodeFormalCodeAssign,
  resetPassword,
  searchSiteCodeFormalCodePair,
  unlockUserAccount,
  updateAccountProfile,
  updateCompanies,
  updateDataAccessProfile,
  updateUserAccount,
  updateUserAccountProfiles,
  getRepairerGroup,
  getRepairerByGroupName,
  getAccountprofilesWorkProvider,
  getStreams,
  getServiceAccounts,
  getDelimitedRepairers,
  getNPSschemecodes,
  getSchemeCodes,
  getWorkProviders,
  getSearchWorkProviderFormalCodes,
} from 'api/resources/adminService';
import { queryClient } from 'api/client';
import {
  getRenderExtensions,
  getHtmlReport,
  getReportParameters,
  getReportPageCount,
  getReportFile,
  getRepairStatusReportCovea,
  getNPSBillingReport,
  gettotalLoss,
  getDLProposedGoodwillReport,
  getSLAPerformanceV3Report,
  getInvoiceExtractReport,
  getDetailedCycleTimeReport,
  getSMSReport,
  getMeasureFuture30report,
  getMeasureRolling30Report,
  getWorkInProgressV3,
  getSMSBillingReport02,
  getB2BRestMessagesReport,
  getVehiclesOnSiteReport,
  getNargControlSheetV5,
  getNpsSummarySiteCodes,
  getNpsSummaryReportV4,
  getNPSDetailV4Report,
  getActiveWebUsageReportV2,
  getAllSitesConnectionReport,
  getFeedbackReport,
  getKey2KeyRSAReport,
  getKey2KeyLVOWNReport,
  getZurichCSIReport,
  getCoreMiReport,
  getVolumeTrendsReport,
  getMonthlyNotificationVolumeReport,
} from 'api/resources/reportsService';
import { AxiosError, AxiosResponse } from 'axios';
import { getMaintenanceConfig } from 'api/resources/maintenanceMessagesService';
import {
  getCycleTimeNetworkAverage,
  getCycleTimeSummaryDetails,
} from 'api/resources/cycleTimeService';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface RepairsOptions {
  recordFilterId: number;
  skip: number;
  take: number;
  siteCode?: string;
  searchText?: string;
  sortString?: string;
  sortOrder?: SortOrder;
}

type RepairsConfig = RepairsOptions | 'disabled';

interface RepairsParams {
  disabled: boolean;
  onSuccess?: () => void;
}

export const getUserCashKey = () => 'user';

export const useUser = ({
  onSuccess,
}: { onSuccess?: (user: User) => void } = {}) => {
  const user = useAppSelector((rootState) => rootState.auth.user);
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    getUserCashKey(),
    getUser,
    {
      staleTime: dayjs.duration(5, 'm').asMilliseconds(),
      onSuccess,
      enabled: !!user?.accessToken,
    }
  );

  return {
    user: data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};

export const getRepairersCacheKey = () => 'repairers';
export const useRepairers = () => {
  const { data, isLoading, error } = useQuery(
    getRepairersCacheKey(),
    getAvailableRepairers,
    {
      staleTime: dayjs.duration(5, 'm').asMilliseconds(),
    }
  );

  data?.forEach((repairer) => {
    if (!repairer.siteCode) {
      const loggerMessage = `Repairer with id '${repairer.repairerId}' has a site code null`;
      logger?.warn(loggerMessage);
      appInsights?.trackException({ error: new Error(loggerMessage), severityLevel: SeverityLevel.Warning });
    }
  });

  return {
    repairers: data ?? [],
    isLoading: isLoading,
    error: error,
  };
};

export const getRepairsCashKey = (config: RepairsConfig) => {
  return config === 'disabled'
    ? ['repairs', undefined, undefined, undefined, undefined]
    : [
        'repairs',
        config.siteCode,
        config.recordFilterId,
        config.skip,
        config.take,
        config.sortString,
        config.sortOrder,
      ];
};
export const useRepairs = (config: RepairsConfig, params: RepairsParams) => {
  const key = getRepairsCashKey(config);
  const {
    data: repairs,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    key,
    () => {
      if (config !== 'disabled') {
        return getRepairs(
          config.recordFilterId,
          config.skip,
          config.take,
          config.siteCode,
          config.searchText,
          config.sortString,
          config.sortOrder
        );
      } else {
        throw new Error(
          'UseRepair hook config is disabled, please provide enabled option.'
        );
      }
    },
    {
      enabled: !params.disabled,
      keepPreviousData: true,
      cacheTime: 0,
      onSuccess: params.onSuccess,
    }
  );
  return {
    repairs,
    isLoading,
    refetch,
    isFetching,
  };
};

interface SearchParams {
  searchText: string;
  skip: number;
  take: number;
  sortString?: string;
  sortOrder?: SortOrder;
}

interface SearchDualParams {
  registration: string;
  claimReference: string;
}

export type SearchConfig = SearchParams | SearchDualParams | 'disabled';

function instanceOfSearchDualParams(
  config: SearchConfig
): config is SearchDualParams {
  return !!(config as SearchDualParams).registration;
}

export const getSearchRepairsKey = (config: SearchConfig) => {
  if (config === 'disabled')
    return ['searchRepairs', undefined, undefined, undefined, undefined];

  if (!instanceOfSearchDualParams(config))
    return [
      'searchRepairs',
      config.searchText,
      config.skip,
      config.take,
      config.sortString,
      config.sortOrder,
    ];
  else return ['searchRepairs', config.registration, config.claimReference];
};

export const useSearchRepairs = (config: SearchConfig, disabled: boolean) => {
  const {
    data: repairs,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    getSearchRepairsKey(config),
    () => {
      if (config !== 'disabled')
        if (!instanceOfSearchDualParams(config))
          return searchRepairs(
            config.searchText,
            config.skip,
            config.take,
            config.sortString,
            config.sortOrder
          );
        else
          return searchRepairsDual(config.registration, config.claimReference);
      else
        throw new Error(
          'UseSearchRepairs hook config is disabled, please provide enabled option.'
        );
    },
    { enabled: !disabled, keepPreviousData: true, cacheTime: 0 }
  );
  return { repairs, search: refetch, isSearching: isLoading || isFetching };
};

export const getRepairClaimSummaryCacheKey = (
  repairId: number,
  repairCode: string | null
) => ['repairClaimSummary', repairId, repairCode];

export const useRepairClaimSummary = (repairId: number, repairCode: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery<ClaimSummary>(
    getRepairClaimSummaryCacheKey(repairId, repairCode),
    () => getRepairClaimSummary(repairId, repairCode)
  );
  return {
    repairSummary: data,
    isRepairSummaryLoading: isLoading,
    isRepairSummaryFetching: isFetching,
    refetchClaimSummary: refetch,
  };
};

export const useRepairGroups = (onError?: (error: AxiosError) => void) => {
  const { data, isLoading, isFetching } = useQuery(
    ['repairerGroup'],
    () => getRepairGroups(),
    { onError }
  );
  return {
    repairerGroups: data || [],
    isRepairGroupsLoading: isLoading || isFetching,
  };
};

export const useNpsSummaryRepairGroups = (
  onError: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['npsSummaryRepairGroups'],
    () => getNpsSummaryRepairGroups(),
    { onError }
  );
  return {
    repairerSites: data || [],
    isRepairSitesLoading: isLoading || isFetching,
  };
};

export const useNpsSummarySchemeCodes = (
  onError: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['npsSummarySchemeCodes'],
    () => getNpsSummarySiteCodes(),
    { onError }
  );
  return {
    siteCodes: data || [],
    isSiteCodesLoading: isLoading || isFetching,
  };
};

export const useServiceAccounts = (onError?: (error: AxiosError) => void) => {
  const { data, isLoading, isFetching } = useQuery(
    ['serviceAccounts'],
    () => getServiceAccounts(),
    { onError }
  );
  return {
    serviceAccounts: data || [],
    isServiceAccountsLoading: isLoading || isFetching,
  };
};

export const getRepairDetailsCacheKey = (repairId: number) => [
  'repairDetails',
  repairId,
];

export const useRepairDetails = (repairId: number) => {
  const { data, isLoading, isFetching } = useQuery<Repair>(
    getRepairDetailsCacheKey(repairId),
    () => getRepairById(repairId)
  );

  return {
    repairDetails: data,
    isRepairDetailsLoading: isLoading,
    isRepairDetailsFetching: isFetching,
  };
};

export const useUpdateRepair = ({
  repairId,
  repairCode,
  siteCode,
  onSuccess,
}: {
  repairId: number;
  repairCode: string | null;
  siteCode: string | null;
  onSuccess?: () => void;
}) => {
  if (repairCode === null)
    throw new Error('During update repair repairCode must be not null');
  if (siteCode === null)
    throw new Error('During update repair siteCode must be not null');

  const { mutateAsync, isLoading } = useMutation(
    (data: RepairManualUpdate) => updateRepair(repairId, repairCode, data),
    {
      onSuccess: () => {
        // _data, { invoice, milestone, replacementVehicle }
        // const prevClaimSummary = queryClient.getQueryData<ClaimSummary>(
        //   getRepairClaimSummaryCacheKey(repairId, repairCode)
        // );
        // if (prevClaimSummary)
        //   queryClient.setQueryData<ClaimSummary | undefined>(
        //     getRepairClaimSummaryCacheKey(repairId, repairCode),
        //     (old) => {
        //       if (!old) return;
        //       return { ...old, invoice, milestone, replacementVehicle };
        //     }
        //   );
        queryClient.invalidateQueries(getRepairDetailsCacheKey(repairId));
        if (onSuccess) onSuccess();
      },
    }
  );
  return { updateRepair: mutateAsync, isUpdating: isLoading };
};

export const useGetRepairerGroup = (
  onError: (error: AxiosError) => void,
  onSuccess?: (data: RepairerGroupModel[]) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['getRepairerGroup'],
    () => getRepairerGroup(),
    { onError, onSuccess }
  );
  return {
    repairerGroupList: data,
    isRepairerGroupListLoading: isLoading || isFetching,
  };
};

export const useRepairerDetails = (repairId: number) => {
  const { data, isLoading } = useQuery(['repairerDetails', repairId], () =>
    getRepairerDetails(repairId)
  );
  return { repairerDetails: data, isLoading };
};

export const useGetRepairerByGroupName = (
  repairerGroup: string | null,
  enabled: boolean,
  onError: (error: AxiosError) => void,
  onSuccess?: (list: RepairerMetaData[]) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['getRepairerByGroupName', repairerGroup],
    () => getRepairerByGroupName(repairerGroup),
    {
      enabled,
      onSuccess: (siteCodes) => {
        if (onSuccess) onSuccess(siteCodes);
      },
      onError,
    }
  );
  return { siteCodes: data, isSiteCodesLoading: isLoading || isFetching };
};

export const useOwnerDetails = (repairId: number) => {
  const { data, isLoading } = useQuery(['ownerDetails', repairId], () =>
    getOwnerDetails(repairId)
  );
  return { ownerDetails: data, isLoading };
};

export const useRepairerContact = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      repairId: number;
      reasonCode: RepairerReason;
      additionalNotes: string | null;
    }) =>
      recordRepairerContactReveal(data.repairId, {
        reasonCode: data.reasonCode,
        additionalNotes: data.additionalNotes,
      })
  );

  return {
    record: (
      repairId: number,
      reasonCode: RepairerReason,
      additionalNotes: string | null
    ) =>
      mutateAsync({
        repairId,
        reasonCode,
        additionalNotes,
      }),
    isRecording: isLoading,
  };
};

export const useOwnerContact = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      repairId: number;
      reasonCode: OwnerReason;
      additionalNotes: string | null;
    }) =>
      recordOwnerContactReveal(data.repairId, {
        reasonCode: data.reasonCode,
        additionalNotes: data.additionalNotes,
      })
  );

  return {
    record: (
      repairId: number,
      reasonCode: OwnerReason,
      additionalNotes: string | null
    ) =>
      mutateAsync({
        repairId,
        reasonCode,
        additionalNotes,
      }),
    isRecording: isLoading,
  };
};

export const useHelpInformation = () => {
  const { data, isLoading } = useQuery('helpInformation', getHelpContext);
  return {
    message: data?.message,
    isLoading: isLoading,
  };
};

export const useAddActivity = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: { repairCode: string; activity: AddActivity }) =>
      addRepairActivity(data.repairCode, data.activity)
  );

  return {
    addActivity: (repairCode: string, activity: AddActivity) =>
      mutateAsync({ repairCode, activity }),
    isAdding: isLoading,
  };
};

interface UseAdminUsersConfig {
  searchTerm?: string | null;
  companyId?: number | null;
  sort: string | null;
  sortOrder: SortOrder | null;
  includeDisabledAccounts: boolean | null;
}

export interface BaseUserAccount {
  id: number;
  userName: string | null;
  firstName: string | null;
  surname: string | null;
  email: string | null;
  companyId: number;
  companyName: string | null;
  teamName: string | null;
  isDisabled: boolean;
  isLocked: boolean;
  createdDate: Date | null;
  expiryDate: Date | null;
  lastLoginDate: Date | null;
  invalidLoginAttempts: number;
  usageCount: number;
  dataAccessProfileName: string;
}

export const convertToDate = (date: string | null | undefined) =>
  date ? new Date(date) : null;

export const convertStringToDate = (
  data: ApiBaseUserAccount[] | undefined
): BaseUserAccount[] | undefined => {
  if (data) {
    return data?.map((element) => {
      return {
        ...element,
        expiryDate: element.expiryDate
          ? convertToDate(element.expiryDate)
          : null,
        createdDate: element.createdDate
          ? convertToDate(element.createdDate)
          : null,
        lastLoginDate: element.lastLoginDate
          ? convertToDate(element.lastLoginDate)
          : null,
      };
    });
  } else return data;
};

export const useUserAccounts = (
  config: UseAdminUsersConfig,
  enabled: boolean
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'adminUsers',
      config.searchTerm,
      config.companyId,
      config.sort,
      config.sortOrder,
      config.includeDisabledAccounts,
    ],
    () =>
      getUsers(
        config.searchTerm ?? undefined,
        config.companyId ?? undefined,
        config.sort ?? undefined,
        config.sortOrder ?? undefined,
        config.includeDisabledAccounts ?? undefined
      ),
    { enabled: enabled, keepPreviousData: true }
  );

  return {
    users: convertStringToDate(data),
    isUsersFetching: isLoading || isFetching,
    loadAdminUsers: refetch,
  };
};

export const useUserAccountsByCompanyId = (companyId: number) => {
  const { data, isLoading, isFetching } = useQuery(
    ['userAccountsByCompanyId'],
    () => getUserAccountsByCompanyId(companyId)
  );
  return {
    userAccounts: convertStringToDate(data),
    userAccountsLoading: isLoading || isFetching,
  };
};

interface useEngineerConfig {
  onSuccess?: (engineers: UserAccount[]) => void;
}
export const useEngineers = ({ onSuccess }: useEngineerConfig) => {
  const { data, isLoading } = useQuery(['engineers'], getEngineers, {
    onSuccess: (engineers) => {
      if (onSuccess) onSuccess(engineers);
    },
  });
  return { engineers: data ?? [], isEngineersLoading: isLoading };
};

export const getUserAccountCashKey = (userId: number) => {
  return ['userAccount', userId];
};

export const useUserAccount = (id: number) => {
  const { data, isLoading, isFetching } = useQuery(
    getUserAccountCashKey(id),
    () => getUserAccount(id)
  );
  return { userAccount: data, isLoading: isLoading || isFetching };
};

interface dataAccessProfilesConfig {
  companyId?: number;
  enabled: boolean;
}
export const useDataAccessProfiles = ({
  companyId,
  enabled,
}: dataAccessProfilesConfig) => {
  const { data, isLoading } = useQuery(
    ['dataAccessProfiles', companyId],
    () => getDataAccessProfiles(companyId),
    { enabled: enabled }
  );
  return {
    dataAccessProfiles: data ?? [],
    isDataAccessProfilesLoading: isLoading,
  };
};

export const useUserAccountProfiles = (id: number) => {
  const { data, refetch, isFetching } = useQuery(
    ['userAccountProfiles', id],
    () => getUserAccountProfiles(id)
  );
  return {
    userAccountProfiles: data ?? [],
    reloadUserAccountProfiles: refetch,
    isReloading: isFetching,
  };
};

interface CompanyId {
  id: number;
}
type userAccountCompanyConfig = CompanyId | 'disabled';

export const useAccountProfilesByCompanyId = (
  config: userAccountCompanyConfig
) => {
  const cacheKey =
    config === 'disabled'
      ? ['accountProfilesByCompanyId', undefined]
      : ['accountProfilesByCompanyId', config.id];
  const { data, isLoading } = useQuery(
    cacheKey,
    () => {
      if (config !== 'disabled')
        return getAccountProfilesByCompanyId(config.id);
    },
    { enabled: config !== 'disabled' }
  );
  return {
    accountProfiles: data?.items ?? [],
    isAccountProfilesLoading: isLoading,
  };
};

export const useAssignableAccountProfilesByCompanyId = (
  config: userAccountCompanyConfig
) => {
  const cacheKey =
    config === 'disabled'
      ? ['assignableAccountProfilesByCompanyId', undefined]
      : ['assignableAccountProfilesByCompanyId', config.id];
  const { data, isLoading } = useQuery(
    cacheKey,
    () => {
      if (config !== 'disabled')
        return getAssignableAccountProfilesByCompanyId(config.id);
    },
    { enabled: config !== 'disabled' }
  );
  return { accountProfiles: data ?? [], isAccountProfilesLoading: isLoading };
};

export const useAccountProfiles = ({
  config,
  onSuccess,
}: {
  config: AccountProfilesConfig;
  onSuccess?: () => void;
}) => {
  const cacheKey = [
    'accountProfiles',
    config.skip,
    config.take,
    config.sortString,
    config.sortOrder,
    config.companyId,
    config.isTemplate,
  ];
  const { data, isLoading } = useQuery(
    cacheKey,
    () =>
      getAccountProfiles({
        skip: config.skip,
        take: config.take,
        sortString: config.sortString,
        sortOrder: config.sortOrder,
        companyId: config.companyId,
        isTemplate: config.isTemplate,
      }),
    { onSuccess }
  );
  return { accountProfiles: data, isAccountProfilesLoading: isLoading };
};

const getAccountProfileCacheKey = (accountProfileId: number) => [
  'accountProfileId',
  accountProfileId,
];

interface accountProfileConfig {
  accountProfileId: number;
  enabled: boolean;
}
export const useAccountProfile = ({
  accountProfileId,
  enabled,
}: accountProfileConfig) => {
  const { data, isLoading } = useQuery(
    getAccountProfileCacheKey(accountProfileId),
    () => getAccountProfile(accountProfileId),
    { enabled: enabled }
  );
  return { accountProfile: data, isLoading };
};

const getAccountProfilePermitsCacheKey = (accountProfileId?: number) => [
  'accountProfilePermits',
  accountProfileId,
];

export const useAccountProfilePermits = (accountProfileId?: number) => {
  const { data, isLoading } = useQuery(
    getAccountProfilePermitsCacheKey(accountProfileId),
    () => getAccountProfilePermits(accountProfileId)
  );
  return { accountProfilePermits: data, isLoading };
};

export const useUpdateUserAccountProfiles = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      userId: number;
      accountProfileIds: number[];
      defaultAccountProfileId: number;
    }) =>
      updateUserAccountProfiles(
        data.userId,
        data.accountProfileIds,
        data.defaultAccountProfileId
      )
  );
  return { updateUserAccountProfiles: mutateAsync, isUpdating: isLoading };
};

export const useCreateUserAccount = () => {
  const { mutateAsync, isLoading } = useMutation((data: UserAccount) =>
    createUserAccount(data)
  );
  return { createUserAccount: mutateAsync, isCreating: isLoading };
};

export const useUpdateUserAccount = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: UserAccount) => updateUserAccount(data),
    {
      onSuccess: (_data, userAccount) => {
        queryClient.setQueriesData(
          getUserAccountCashKey(userAccount.id),
          userAccount
        );
      },
    }
  );
  return { updateUserAccount: mutateAsync, isUpdating: isLoading };
};

interface UserAccountByProfileId {
  accountProfileId: number | undefined;
}

const getUserAccountsByProfileIdCacheKey = (config: UserAccountByProfileId) => [
  'getAccountProfileByProfileIdCacheKey',
  config.accountProfileId,
];

export const useUserAccountsByProfileId = (
  config: UserAccountByProfileId,
  enabled: boolean
) => {
  const { data, isLoading } = useQuery(
    getUserAccountsByProfileIdCacheKey(config),
    () => getUserAccountsByProfileId(config),
    { enabled }
  );
  return { userAccounts: data, isUserAccountsLoading: isLoading };
};

interface useCompaniesConfig {
  onSuccess?: (companies: Company[]) => void;
}
export const useCompanies = ({ onSuccess }: useCompaniesConfig) => {
  const { data, isLoading } = useQuery(['companies'], getCompanies, {
    onSuccess: (companies) => {
      if (onSuccess) onSuccess(companies);
    },
  });
  return { companies: data ?? [], isCompaniesLoading: isLoading };
};

const getCompanyPermitsCacheKey = (companyId?: number) => [
  'companyDataById',
  companyId,
];

export const useCompaniesById = ({
  onSuccess,
  companyId,
}: {
  onSuccess?: (data: Company) => void;
  companyId: number;
}) => {
  const { data, isLoading } = useQuery(
    getCompanyPermitsCacheKey(companyId),
    () => getCompaniesById(companyId),
    {
      onSuccess: (companies: Company) => {
        if (onSuccess) onSuccess(companies);
      },
    }
  );
  return { company: data ?? undefined, isLoading: isLoading };
};

export const useCreateCompany = () => {
  const { mutateAsync, isLoading } = useMutation((data: Company) =>
    createCompany(data)
  );
  return { createCompany: mutateAsync, isCreating: isLoading };
};

export const useUpdateCompany = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: Company) => updateCompanies(data),
    {
      onSuccess: (_data, company) => {
        queryClient.setQueriesData(
          getCompanyPermitsCacheKey(company.id),
          company
        );
      },
    }
  );
  return { updateCompany: mutateAsync, isUpdating: isLoading };
};

export const getDataAccessProfileCashKey = (dataAccessProfileId: number) => [
  'dataAccessProfile',
  dataAccessProfileId,
];

export const useDataAccessProfile = (dataAccessProfileId: number) => {
  const { data, isLoading, isFetching } = useQuery(
    getDataAccessProfileCashKey(dataAccessProfileId),
    () => getDataAccessProfile(dataAccessProfileId)
  );
  return {
    dataAccessProfile: data,
    isDataAccessProfilesLoading: isLoading || isFetching,
  };
};

export const useSiteCodes = () => {
  const { data, isLoading } = useQuery(['siteCodes'], getSiteCodes, {
    staleTime: dayjs.duration(5, 'm').asMilliseconds(),
  });
  return { siteCodes: data ?? [], isSiteCodesLoading: isLoading };
};

export const useWorkProviderFormalCodes = () => {
  const { data, isLoading } = useQuery(
    ['workProviderFormalCodes'],
    getWorkProviderFormalCodes,
    { staleTime: dayjs.duration(5, 'm').asMilliseconds() }
  );
  return {
    workProviderFormalCodes: data ?? [],
    isFormalCodesLoading: isLoading,
  };
};

export const useWorkProviders = () => {
  const { data, isLoading } = useQuery(
    ['workProviders'],
    getWorkProviders,
    { staleTime: dayjs.duration(5, 'm').asMilliseconds() }
  );
  return {
    workProviders: data ?? [],
    isWorkProvidersCodesLoading: isLoading,
  };
};

export const useSearchWorkProviderFormalCodes = (searchText: string | null) => {
  const { data, isLoading, refetch } = useQuery(
    ['searchWorkProviderFormalCodes'],
    () => getSearchWorkProviderFormalCodes(searchText),
    {
      staleTime: dayjs.duration(5, 'm').asMilliseconds(),
      enabled: !!searchText,
    }
  );
  return {
    searchWorkProviderFormalCodes: data ?? [],
    isFormalCodesLoading: isLoading,
    refetchFormalCodes: refetch,
  };
};

export const useSchemeCodes = (onError: (error: AxiosError) => void) => {
  const { data, isLoading } = useQuery(['schemeCodes'], getSchemeCodes, {
    staleTime: dayjs.duration(5, 'm').asMilliseconds(),
    onError,
  });
  return {
    schemeCodes: data ?? [],
    isSchemeCodesLoading: isLoading,
  };
};

export const useGetAccountprofilesWorkProvider = (
  dataAccessProfileId: number
) => {
  const { data, isLoading, refetch } = useQuery(
    ['getAccountprofilesWorkProvider', dataAccessProfileId],
    () => getAccountprofilesWorkProvider(dataAccessProfileId)
  );
  return {
    accountprofilesWorkProvider: data ?? [],
    isWorkProvidersLoading: isLoading,
    reFetchWorkProviders: refetch,
  };
};

export const useGetStreams = (
  onError: (error: AxiosError) => void,
  onSuccess?: (list: WorkProvider[]) => void
) => {
  const { data, isLoading } = useQuery(['getStreams'], () => getStreams(), {
    onError,
    onSuccess: (streamsList) => {
      if (onSuccess) onSuccess(streamsList);
    },
  });
  return {
    streamsList: data ?? [],
    isStramsLoading: isLoading,
  };
};

export const useAddWorkProviderFormalCodes = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      accountProfilePermitMasterIds: number[];
      accountProfileId: number;
    }) =>
      addWorkProviderformalcodes(
        data.accountProfilePermitMasterIds,
        data.accountProfileId
      )
  );
  return { addcode: mutateAsync, isAdding: isLoading };
};

interface SiteCodeFormalCodePair {
  dataAccessProfileId: number;
  siteCode: string;
  formalCode: string;
}
type SiteCodeFormalCodePairConfig = SiteCodeFormalCodePair | 'disabled';

export const getSiteCodeFormalCodeSearchCashKey = (
  searchConfig: SiteCodeFormalCodePairConfig
) => [
  'siteCodeFormalCodeSearch',
  ...(searchConfig === 'disabled'
    ? [undefined, undefined]
    : [searchConfig.siteCode, searchConfig.formalCode]),
];

export const useSiteCodeFormalCodePairSearch = (
  searchConfig: SiteCodeFormalCodePairConfig,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, refetch } = useQuery(
    getSiteCodeFormalCodeSearchCashKey(searchConfig),
    () => {
      if (searchConfig !== 'disabled')
        return searchSiteCodeFormalCodePair(
          searchConfig.dataAccessProfileId,
          searchConfig.siteCode,
          searchConfig.formalCode
        );
    },
    { onError: onError, enabled: false, cacheTime: 0 }
  );
  return {
    searchResult: data,
    isSearching: isLoading,
    search: refetch,
  };
};

export interface ISiteCodeOnSuccess {
  response: AxiosResponse;
  siteCode: string;
  formalCode: string;
}

export interface ISiteCodeOnError {
  siteCode: string;
  formalCode: string;
}

export const useRemoveSiteCodeFormalCodeAsign = (removeSiteCode?: {
  onSuccess?: ({ response, siteCode, formalCode }: ISiteCodeOnSuccess) => void;
  onError?: ({ siteCode, formalCode }: ISiteCodeOnError) => void; 
}) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      dataAccessProfileId: number;
      siteCode: string;
      formalCode: string;
    }) =>
      removeSiteCodeFormalCodeAssign(
        data.dataAccessProfileId,
        data.siteCode,
        data.formalCode
      ),
    {
      onSuccess: (data, { dataAccessProfileId, siteCode, formalCode }) => {
        queryClient.setQueryData(
          getSiteCodeFormalCodeSearchCashKey({
            dataAccessProfileId,
            siteCode,
            formalCode,
          }),
          { allowed: false, exists: true }
        );
        if (removeSiteCode?.onSuccess)
          removeSiteCode.onSuccess({ response: data, siteCode, formalCode });
      },
      onError: (data, { siteCode, formalCode }) => {
        if (removeSiteCode?.onError)
          removeSiteCode.onError({ siteCode, formalCode });
      },
    }
  );
  return { removeSiteCodeFormalCodeAssign: mutateAsync, isRemoving: isLoading };
};

export const useAddSiteCodeFormalCodeAssign = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      dataAccessProfileId: number;
      siteCode: string;
      formalCode: string;
    }) =>
      addSiteCodeFormalCodeAssign(
        data.dataAccessProfileId,
        data.siteCode,
        data.formalCode
      ),
    {
      onSuccess: (_data, { dataAccessProfileId, siteCode, formalCode }) => {
        queryClient.setQueryData(
          getSiteCodeFormalCodeSearchCashKey({
            dataAccessProfileId,
            siteCode,
            formalCode,
          }),
          { allowed: true, exists: true }
        );
      },
    }
  );
  return { addSiteCodeFormalCodeAssign: mutateAsync, isAdding: isLoading };
};

export const useAddAllSiteCodeForFormalCode = (formalcode: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    'sitecodeforformalcode',
    () => {
      if (formalcode) {
        return addAllSiteCodeForFormalCode(formalcode);
      }
    }
  );
  return {
    siteCodeForFormalCode: data ?? [],
    siteCodeLoading: isLoading || isFetching,
    siteCoderRefetch: refetch,
  };
};

export const useGetALLSiteCodeFormalCode = (dataAccessProfileId: number) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    ['allSiteCodeFormalCode', dataAccessProfileId],
    () => {
      return getALLSiteCodeFormalCode(dataAccessProfileId);
    }
  );
  return {
    allSiteCodeFormalCode: data ?? [],
    isAllSiteCodeLoading: isLoading || isFetching,
    refetchAllSiteCodeFormalCode: refetch,
  };
};

export const useUpdateDataAccessProfile = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: DataAccessProfile) => updateDataAccessProfile(data),
    {
      onSuccess: (profile) =>
        queryClient.invalidateQueries(getDataAccessProfileCashKey(profile.id)),
    }
  );
  return { update: mutateAsync, isUpdating: isLoading };
};

export const useCreateDataAccessProfile = () => {
  const { mutateAsync, isLoading } = useMutation((data: DataAccessProfile) =>
    createDataAccessProfile(data)
  );
  return { create: mutateAsync, isCreating: isLoading };
};

export interface ReportConfig {
  reportPath: string;
  pageNumber: number;
  params: { [name: string]: string }[];
}

interface ReportQueryConfig {
  enabled: boolean;
}

const getReportCachKey = (config: ReportConfig) => [
  'report',
  config.reportPath,
  config.pageNumber,
  config.params,
];

export const useHtmlReport = (
  config: ReportConfig,
  queryConfig: ReportQueryConfig
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getReportCachKey(config),
    () => getHtmlReport(config.reportPath, config.pageNumber, config.params),
    {
      enabled: queryConfig.enabled,
      cacheTime: 0,
    }
  );
  return {
    htmlReport: data,
    isReportLoading: isLoading || isFetching,
    loadReport: refetch,
  };
};

interface ReportFileConfig {
  reportPath: string;
  params: { [name: string]: string }[];
}
interface ReportFileQueryConfig {
  enabled: boolean;
  onSuccess: (file: AxiosResponse) => void;
}
export const useReportFile = (
  config: ReportFileConfig,
  queryConfig: ReportFileQueryConfig
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['reportFile', config.reportPath, config.params],
    () => getReportFile(config.reportPath, config.params),
    { enabled: queryConfig.enabled, onSuccess: queryConfig.onSuccess }
  );
  return {
    file: data,
    download: refetch,
    isDownloading: isLoading || isFetching,
  };
};

export const useReportParameters = (reportPath: string) => {
  const { data, isLoading } = useQuery(['reportParameters', reportPath], () =>
    getReportParameters(reportPath)
  );
  return { reportParameters: data, isParametersLoading: isLoading };
};

export const useRenderExtensions = () => {
  const { data, isLoading } = useQuery(
    ['renderExtensions'],
    getRenderExtensions
  );
  return { renderExtensions: data, isParametersLoading: isLoading };
};

export const useReportPageCount = (
  config: ReportFileConfig,
  queryConfig: ReportQueryConfig
) => {
  const { data, isLoading, refetch } = useQuery(
    ['reportPageCount', config.reportPath, config.params],
    () => getReportPageCount(config.reportPath, config.params),
    { enabled: queryConfig.enabled, cacheTime: 0 }
  );
  return {
    pageCount: data,
    loadPageCount: refetch,
    isPageCountLoading: isLoading,
  };
};

export const useDisableUser = () => {
  const { mutateAsync, isLoading } = useMutation((userId: number) =>
    disableUserAccount(userId)
  );
  return { disableUser: mutateAsync, isDisabling: isLoading };
};

export const useResetPassword = () => {
  const { mutateAsync, isLoading } = useMutation((email: string) =>
    resetPassword(email)
  );
  return { resetPassword: mutateAsync, isReseting: isLoading };
};

export const useNotifyUser = () => {
  const { mutateAsync, isLoading } = useMutation((email: string) =>
    notifyUser(email)
  );
  return { notifyUser: mutateAsync, isNotifying: isLoading };
};

export const useImpersonateUser = () => {
  const { mutateAsync, isLoading } = useMutation((userId: number) =>
    impersonateUser(userId)
  );
  return { impersonateUser: mutateAsync, isImpersonating: isLoading };
};

export const useEnableUser = () => {
  const { mutateAsync, isLoading } = useMutation((userId: number) =>
    enableUserAccount(userId)
  );
  return { enableUser: mutateAsync, isEnabling: isLoading };
};

export const useUnlockUser = () => {
  const { mutateAsync, isLoading } = useMutation((userId: number) =>
    unlockUserAccount(userId)
  );
  return { unlockUser: mutateAsync, isUnlocking: isLoading };
};

interface MaintenanceOptions {
  refetchInterval: number;
  enabled: boolean;
}

export const useMaintenanceConfig = ({
  refetchInterval,
  enabled,
}: MaintenanceOptions) => {
  const { data } = useQuery('maintenanceConfig', getMaintenanceConfig, {
    refetchInterval: refetchInterval,
    enabled: enabled,
  });

  return { maintenanceConfig: data };
};

function getAllPermitIds(accountProfilePermits: AccountProfilePermit[] | null) {
  return accountProfilePermits?.map(getPermitIds).flat() ?? [];
}
function getPermitIds(permit: AccountProfilePermit): number[] {
  const id = permit.isActive ? [permit.accountProfilePermitId] : [];
  const childIds = permit.children ? permit.children.map(getPermitIds) : [];
  return id.concat(childIds.flat());
}
function getReportPermitIds(permit: AccountProfilePermit): number[] {
  return permit.isActive ? [permit.accountProfilePermitId] : [];
}
const permitIds = (permits: AccountProfilePermits) => [
  ...getAllPermitIds(permits.adminPermits),
  ...getAllPermitIds(permits.repairsListsPermits.corePermits),
  ...getAllPermitIds(permits.repairsListsPermits.fieldLevelPermits),
  ...getAllPermitIds(permits.repairsDetailsPermits),
  ...getReportPermitIds(permits.reportPermits),
];

const recordFilterIds = (permits: AccountProfilePermit[]): number[] => [
  ...getAllPermitIds(permits),
];

const reportChildrenPermits = (permits: AccountProfilePermits) => [
  ...getAllPermitIds(permits.reportPermits.children),
];

export const useCreateAccountProfile = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      accountProfile: AccountProfile;
      permits: AccountProfilePermits;
    }) =>
      createAccountProfile(
        data.accountProfile,
        permitIds(data.permits),
        recordFilterIds(data.permits.recordFiltersPermits),
        reportChildrenPermits(data.permits)
      ),
    {
      onSuccess: (_data, { accountProfile }) => {
        queryClient.setQueryData(
          getAccountProfileCacheKey(accountProfile.accountProfileId),
          accountProfile
        );
      },
    }
  );
  return {
    createAccountProfile: mutateAsync,
    isCreatingAccountProfile: isLoading,
  };
};

export const useUpdateAccountProfile = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: {
      accountProfile: AccountProfile;
      permits: AccountProfilePermits;
    }) =>
      updateAccountProfile(
        data.accountProfile,
        permitIds(data.permits),
        recordFilterIds(data.permits.recordFiltersPermits),
        reportChildrenPermits(data.permits)
      ),
    {
      onSuccess: (_data, { accountProfile, permits }) => {
        queryClient.setQueryData(
          getAccountProfileCacheKey(accountProfile.accountProfileId),
          accountProfile
        );

        queryClient.setQueryData(
          getAccountProfilePermitsCacheKey(accountProfile.accountProfileId),
          permits
        );
      },
    }
  );
  return {
    updateAccountProfile: mutateAsync,
    isUpdatingAccountProfile: isLoading,
  };
};

export const getDataEngineerCashKey = (repairId: number) => [
  'engineer',
  repairId,
];

export const useUpdateEngineer = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: Engineer) => updateEngineer(data),
    {
      onSuccess: (engineer) =>
        queryClient.invalidateQueries(
          getDataEngineerCashKey(engineer.repairId)
        ),
    }
  );
  return { updateEngineer: mutateAsync, isUpdating: isLoading };
};

export const useDtlList = () => {
  const { data, isLoading } = useQuery(['dtlList'], getDtlList);
  return { dtlList: data ?? [], isLoading };
};

interface ReportDetailedCycleTimeConfig {
  startDate: string | null;
  endDate: string | null;
  repairerGroup: string | null;
  siteCode: string | null;
  showExcep: boolean;
}

interface ReportRolling30DayConfig {
  runDate: string | null;
  repairerGroup: string | null;
  siteCodes: string | null;
  rollingMeasureCodes: string | null;
  wpMasterIds: string | null;
}

interface ReportVehiclesOnSiteConfig {
  startDate: string | null;
  endDate: string | null;
  priorFullMonth: boolean | null;
}

interface ReportNPSDetailV4Config {
  startDate: string | null;
  endDate: string | null;
  repairSites: string | null;
  schemeCodes: string | null;
  nPSScore: string | null;
  groupingValue: string | null;
  reportGrouping: string | null;
}
interface ReportDateConfig {
  startDate: string | null;
  endDate: string | null;
}

interface ReportSLAPerformanceV3Config {
  startDate: string | null;
  endDate: string | null;
  repairerGroup: string | null;
  siteCode: string | null;
}
interface InvoiceExtractReportConfig {
  startDate: string | null;
  endDate: string | null;
  dataAccessProfileName: string | null;
  basedOnCreatedDateOnly: boolean;
}

interface WorkInProgressV3Report {
  // startDate?: string | null;
  // endDate?: string | null;
  repairerGroup: string | null;
}
interface SMSBillingReport02 {
  startDate: string | null;
  endDate: string | null;
  workProvider: string | null;
}
interface NargControlSheetV5 {
  startDate: string | null;
  endDate: string | null;
  schemaCodeList: string | null;
}
interface NPSSummaryReportV4 {
  endDate: string | null;
  repairSites: string | null;
  schemeCodes: string | null;
  noOfDateGroups: string | null;
  reportingGroup: string | null;
}

interface B2BRestQueueMessage {
  startDate: string | null;
  endDate: string | null;
  userAccountId: string | null;
}

export const useRepairStatusReportCovea = (
  config: ReportDateConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['repairStatusReportCovea', config.startDate, config.endDate],
    () => getRepairStatusReportCovea(config.startDate, config.endDate),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,

    refetchReport: refetch,
  };
};

export const useNPSBillingReport = (
  config: ReportDateConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['npsBillingReport', config.startDate, config.endDate],
    () => getNPSBillingReport(config.startDate, config.endDate),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useSMSReport = (
  config: ReportDateConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['smsReport', config.startDate, config.endDate],
    () => getSMSReport(config.startDate, config.endDate),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useWorkInProgressV3Report = (
  config: WorkInProgressV3Report,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'workInProgressV3Report',
      // config.startDate,
      // config.endDate,
      config.repairerGroup,
    ],
    () =>
      getWorkInProgressV3(
        config.repairerGroup
        // config.startDate,
        // config.endDate
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useB2BRestMessagesReport = (
  config: B2BRestQueueMessage,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'B2BRestQueueMessages',
      config.startDate,
      config.endDate,
      config.userAccountId,
    ],
    () =>
      getB2BRestMessagesReport(
        config.startDate,
        config.endDate,
        config.userAccountId
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useNargControlSheetV5 = (
  config: NargControlSheetV5,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'nargControlSheetV5',
      config.startDate,
      config.endDate,
      config.schemaCodeList,
    ],
    () =>
      getNargControlSheetV5(
        config.startDate,
        config.endDate,
        config.schemaCodeList
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useNPSSummaryReportV4 = (
  config: NPSSummaryReportV4,
  enabled: boolean,
  onError: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'nargControlSheetV5',
      config.endDate,
      config.repairSites,
      config.schemeCodes,
      config.noOfDateGroups,
      config.reportingGroup,
    ],
    () =>
      getNpsSummaryReportV4(
        config.endDate,
        config.repairSites,
        config.schemeCodes,
        config.noOfDateGroups,
        config.reportingGroup
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useSMSBillingReport02 = (
  config: SMSBillingReport02,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'workInProgressV3Report',
      config.startDate,
      config.endDate,
      config.workProvider,
    ],
    () =>
      getSMSBillingReport02(
        config.startDate,
        config.endDate,
        config.workProvider
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useInvoiceExtractReport = (
  config: InvoiceExtractReportConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'invoiceExtractReport',
      config.startDate,
      config.endDate,
      config.dataAccessProfileName,
      config.basedOnCreatedDateOnly,
    ],
    () =>
      getInvoiceExtractReport(
        config.startDate,
        config.endDate,
        config.dataAccessProfileName,
        config.basedOnCreatedDateOnly,
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,

    refetchReport: refetch,
  };
};

export const useTotalLoss = (
  config: ReportDateConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['totalLossSchedule', config.startDate, config.endDate],
    () => gettotalLoss(config.startDate, config.endDate),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,

    refetchReport: refetch,
  };
};

export const useDLProposedGoodwilReport = (
  config: ReportDateConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['dlProposedGoodwillReport', config.startDate, config.endDate],
    () => getDLProposedGoodwillReport(config.startDate, config.endDate),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,

    refetchReport: refetch,
  };
};

export const useSLAPerformanceV3 = (
  config: ReportSLAPerformanceV3Config,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'SLAPerformanceV3',
      config.startDate,
      config.endDate,
      config.repairerGroup,
      config.siteCode,
    ],
    () =>
      getSLAPerformanceV3Report(
        config.startDate,
        config.endDate,
        config.repairerGroup,
        config.siteCode
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useGetDetailedCycleTimeReport = (
  config: ReportDetailedCycleTimeConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'getDetailedCycleTimeReport',
      config.startDate,
      config.endDate,
      config.repairerGroup,
      config.siteCode,
      config.showExcep,
    ],
    () =>
      getDetailedCycleTimeReport(
        config.startDate,
        config.endDate,
        config.repairerGroup,
        config.siteCode,
        config.showExcep
      ),
    { enabled: enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    reFetchReportData: refetch,
  };
};

export const useGetMeasureFuture30report = (
  config: ReportRolling30DayConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'getMeasureFuture30report',
      config.runDate,
      config.repairerGroup,
      config.siteCodes,
      config.rollingMeasureCodes,
      config.wpMasterIds,
    ],
    () =>
      getMeasureFuture30report(
        config.runDate,
        config.repairerGroup,
        config.siteCodes,
        config.rollingMeasureCodes,
        config.wpMasterIds
      ),
    { enabled: enabled, onError }
  );
  return {
    measureFuture30ReportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,

    refetchMeasureFuture30ReportData: refetch,
  };
};

export const useGetMeasureRolling30Report = (
  config: ReportRolling30DayConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'getMeasureRolling30Report',
      config.runDate,
      config.repairerGroup,
      config.siteCodes,
      config.rollingMeasureCodes,
      config.wpMasterIds,
    ],
    () =>
      getMeasureRolling30Report(
        config.runDate,
        config.repairerGroup,
        config.siteCodes,
        config.rollingMeasureCodes,
        config.wpMasterIds
      ),
    { enabled: enabled, onError }
  );
  return {
    measureRolling30ReportData: data ?? undefined,
    isRollingReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useGetVehiclesOnSiteReport = (
  config: ReportVehiclesOnSiteConfig,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'getVehiclesOnSiteReport',
      config.startDate,
      config.endDate,
      config.priorFullMonth,
    ],
    () =>
      getVehiclesOnSiteReport(
        config.startDate,
        config.endDate,
        config.priorFullMonth
      ),
    { enabled, onError }
  );

  return {
    vehiclesOnSiteReportData: data,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useGetDelimitedRepairers = (
  onError: (error: AxiosError) => void
) => {
  const { data, isLoading } = useQuery(
    ['getDelimitedRepairers'],
    () => getDelimitedRepairers(),
    { onError }
  );
  return { delimitedRepairersList: data, isLoading };
};

export const useGetNPSschemecodes = (onError: (error: AxiosError) => void) => {
  const { data, isLoading } = useQuery(
    ['getNPSschemecodes'],
    () => getNPSschemecodes(),
    { onError }
  );
  return { NPSschemeCodes: data, isFetching: isLoading };
};

export const useGetNPSDetailV4Report = (
  config: ReportNPSDetailV4Config,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [
      'getNPSDetailV4Report',
      config.startDate,
      config.endDate,
      config.repairSites,
      config.schemeCodes,
      config.nPSScore,
      config.groupingValue,
      config.reportGrouping,
    ],
    () =>
      getNPSDetailV4Report(
        config.startDate,
        config.endDate,
        config.repairSites,
        config.schemeCodes,
        config.nPSScore,
        config.groupingValue,
        config.reportGrouping
      ),
    { enabled: enabled, onError }
  );
  return {
    nPSDetailV4ReportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,

    refetchReport: refetch,
  };
};

export const useActiveWebUsageReport = (
  accountStatus: string | null,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['ActiveWebUsageReport', accountStatus],
    () => getActiveWebUsageReportV2(accountStatus),
    { enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useAllSiteConnectionsReport = (
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['AllSiteConnectionsReport'],
    () => getAllSitesConnectionReport(),
    { onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};
export const useFeedbackReport = (
  startDate: string | null,
  endDate: string | null,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['feedbackReport', startDate, endDate],
    () => getFeedbackReport(startDate, endDate),
    { enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useCycleTimeNetworkAverage = (
  startDate: string | null,
  endDate: string | null,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['cycleTimeNetworkAverage', startDate, endDate],
    () => getCycleTimeNetworkAverage(startDate, endDate),
    { enabled, onError }
  );
  return {
    networkAverageData: data ?? undefined,
    isDataFetching: isLoading || isFetching,
  };
};

export const useCycleTimeSummaryDetails = (
  params: CycleTimeRequestModel,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['cycleTimeSummaryDetail', params],
    () => getCycleTimeSummaryDetails(params),
    { enabled, onError }
  );
  return {
    summaryDetailsData: data ?? undefined,
    isDataFetching: isLoading || isFetching,
  };
};

export const useRSAKey2KeyReport = (
  year: string | null,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['RSAKey2KeyReport', year],
    () => getKey2KeyRSAReport(year),
    { enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useLVOWNKey2KeyReport = (
  year: string | null,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['LVOWNKey2KeyReport', year],
    () => getKey2KeyLVOWNReport(year),
    { enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useZurichCSIReport = (
  startDate: string | null,
  endDate: string | null,
  enabled: boolean,
  onError?: (error: AxiosError) => void
) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['zurichCSIReport', startDate, endDate],
    () => getZurichCSIReport(startDate, endDate),
    { enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useCoreMIReport = ({
  enabled,
  endDate,
  startDate,
  onError,
  isInitialWorkCompletedDate,
}: {
  startDate: string | null;
  endDate: string | null;
  enabled: boolean;
  onError?: (error: AxiosError) => void;
  isInitialWorkCompletedDate: boolean;
}) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['coreMiReport', startDate, endDate, isInitialWorkCompletedDate],
    () => getCoreMiReport(startDate, endDate, isInitialWorkCompletedDate),
    { enabled, onError }
  );
  return {
    reportData: data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useVolumeTrendsReport = ({
  enabled,
  endDate,
  startDate,
  onError,
}: {
  startDate: string | null;
  endDate: string | null;
  enabled: boolean;
  onError?: (error: AxiosError) => void;
}) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['volumeTrendsReport', startDate, endDate],
    () => getVolumeTrendsReport(startDate, endDate),
    { enabled, onError }
  );

  return {
    reportData: data?.data ?? undefined,
    isReportFetching: isLoading || isFetching,
    refetchReport: refetch,
  };
};

export const useMonthlyNotificationVolumeReport = (
  onError?: (error: AxiosError) => void
) => {
  const { data, isLoading, isFetching } = useQuery(
    ['MonthlyNotificationVolumeReport'],
    () => getMonthlyNotificationVolumeReport(),
    { onError }
  );

  return {
    reportData: data?.data ?? undefined,
    isReportFetching: isLoading || isFetching,
  };
};
