import { api } from 'api';
import { config } from 'core/config';
import {
  AddActivity,
  ClaimSummary,
  DtlItem,
  Engineer,
  Owner,
  ReasonLogOfOwnerReason,
  ReasonLogOfRepairerReason,
  Repair,
  Repairer,
  RepairerDetails,
  RepairerGroupsResult,
  RepairerMetaData,
  RepairManualUpdate,
  Repairs,
  SortOrder,
  UserAccount,
} from './models/AutoGenerated';

const repairersBaseUrl = '/api/repairers';
const repairsBaseUrl = '/api/repairs';
const timeout = 60000;

export const getAvailableRepairers = (): Promise<Repairer[]> =>
  api
    .get(`${config.activewebApiUrl}${repairersBaseUrl}`)
    .then((response) => response.data);

export const getRepairerDetails = (
  repairId: number
): Promise<RepairerDetails> =>
  api
    .get(
      `${config.activewebApiUrl}${repairsBaseUrl}/${repairId}/repairer-details`
    )
    .then((response) => response.data);

export const getOwnerDetails = (repairId: number): Promise<Owner> =>
  api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/${repairId}/owner`)
    .then((response) => response.data);

export const getRepairs = (
  recordFilterId: number,
  skip: number,
  take: number,
  siteCode?: string,
  searchText?: string,
  sortString?: string,
  sortOrder?: SortOrder
): Promise<Repairs> => {
  return api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}`, {
      params: {
        recordFilterId: recordFilterId,
        siteCode: siteCode,
        searchText: searchText,
        sortString: sortString,
        sortOrder: sortOrder,
        skip: skip,
        take: take,
      },
    })
    .then((response) => response.data);
};

export const searchRepairs = (
  searchText: string,
  skip: number,
  take: number,
  sortString?: string,
  sortOrder?: SortOrder
): Promise<Repairs> => {
  return api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/search`, {
      params: {
        searchText: searchText,
        sortString: sortString,
        sortOrder: sortOrder,
        skip: skip,
        take: take,
      },
    })
    .then((response) => response.data);
};

export const searchRepairsDual = (
  registration: string,
  claimReference: string
): Promise<Repairs> => {
  return api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/search-dual`, {
      params: {
        registration,
        claimReference,
      },
    })
    .then((response) => response.data);
};

export const getRepairClaimSummary = (
  repairId: number,
  repairCode: string
): Promise<ClaimSummary> =>
  api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/${repairId}/summary`, {
      params: { repairCode },
    })
    .then((response) => response.data);

export const getRepairById = (repairId: number): Promise<Repair> =>
  api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/${repairId}`)
    .then((response) => response.data);

export const getRepairGroups = (): Promise<RepairerGroupsResult[]> =>
  api
    .get(
      `${config.activewebApiUrl}${repairersBaseUrl}/repairerGroupByNameV3
    `,
      { timeout }
    )
    .then((response) => response.data);

export const getNpsSummaryRepairGroups = (): Promise<RepairerMetaData[]> =>
  api
    .get(
      `${config.activewebApiUrl}${repairersBaseUrl}/delimitedRepairers
    `,
      { timeout }
    )
    .then((response) => response.data);

export const recordRepairerContactReveal = (
  repairId: number,
  data: ReasonLogOfRepairerReason
): Promise<void> =>
  api.post(
    `${config.activewebApiUrl}${repairsBaseUrl}/${repairId}/repairer-contact-log`,
    data
  );

export const recordOwnerContactReveal = (
  repairId: number,
  data: ReasonLogOfOwnerReason
): Promise<void> =>
  api.post(
    `${config.activewebApiUrl}${repairsBaseUrl}/${repairId}/owner-contact-log`,
    data
  );

export const addRepairActivity = (
  repairCode: string,
  activity: AddActivity
): Promise<void> =>
  api.post(
    `${config.activewebApiUrl}${repairsBaseUrl}/${repairCode}/activity`,
    activity
  );

export const getSiteCodes = (): Promise<string[]> =>
  api
    .get(`${config.activewebApiUrl}${repairersBaseUrl}/site-codes`)
    .then((response) => response.data);

export const updateRepair = (
  repairID: number,
  repairCode: string,
  repair: RepairManualUpdate
) =>
  api.patch(
    `${config.activewebApiUrl}${repairsBaseUrl}/${repairID}/${repairCode}`,
    repair
  );

export const getEngineers = (): Promise<UserAccount[]> => {
  return api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/engineers`)
    .then((response) => response.data);
};

export const updateEngineer = (engineer: Engineer): Promise<Engineer> =>
  api
    .patch(
      `${config.activewebApiUrl}${repairsBaseUrl}/engineers/update`,
      engineer
    )
    .then((response) => response.data);

export const getDtlList = (): Promise<DtlItem[]> =>
  api
    .get(`${config.activewebApiUrl}${repairsBaseUrl}/dtl-list`)
    .then((response) => response.data);
